<template>
  <div class=" border  rounded-bottom p-2">
    <b-input-group class="input-group-merge">
      <b-form-input
          @keydown.enter="sendNewMessage"
          id="search-input"
          v-model.trim="content"
          placeholder="write your message"
      />
      <b-input-group-append
          class="cursor-pointer bg-primary d-flex align-items-center justify-content-center rounded-right"
          style="width: 50px;outline: none;box-shadow: none!important"
          @click="sendNewMessage"
      >
        <feather-icon
            class="text-white"
            icon="SendIcon"
            size="20"
        />
      </b-input-group-append>
    </b-input-group>
  </div>

</template>

<script>
import ChatHeader from '@/views/components/newChat/ChatHeader.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmojiPicker from '@/views/components/chat/EmojiPicker.vue'
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ChatEntries',
  data() {
    return {
      content: ''
    }
  },
  methods: {

    async sendNewMessage() {
      this.$emit('sendNewMessage', this.content)
    },
    clearMessage() {
      this.content = ''
    }
  },
  components: {
    ChatHeader,
    VuePerfectScrollbar,
    EmojiPicker,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BAlert,
    BMedia,
    BAvatar,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style scoped>

</style>
