import Helper from '@/libs/Helper'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import useJwt from '@/auth/jwt/useJwt'

let hub = null
const hubInstance = {
  createNewHubInstance: function () {
    try {
      if (!hub) {
        hub = new HubConnectionBuilder()
          .configureLogging(LogLevel.Information)
          .withUrl(`${Helper.baseUrl}hub/chatHub`, {
            accessTokenFactory: function () {
              return `${useJwt.getToken()}`
            },
            headers: {
               'Access-Control-Allow-Origin': '*',
            },
          })
          .withAutomaticReconnect([0, 0, 5000, 10000])
          .build()
      }
      return hub
    }
    catch (e) {
      console.log(e)
    }
  },

  connectToServer: async function (successCallBack) {
    try {
      await hub.start()
      return successCallBack(hub)
    }
    catch (e) {
      console.log(e)
    }
  },
  getInstance: async function () {
    if (hub.state === 'Connected') {
      return hub
    } else {
      await this.connectToServer()
    }
  },

  getInstanceState: function () {
    return hub.state
  },

  closeInstance: async function () {
    if (hub) {
      hub.disconnected()
    }
  }
}

export { hubInstance }
