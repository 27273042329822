<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <b-card>
      <div class="d-flex flex-column w-100">
        <ChatHeader></ChatHeader>
        <vue-perfect-scrollbar
            id="chat-container"
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="  position-relative chat-bg  p-lg-2 p-25 overflow-y-scroll"
            style="min-height: 550px; max-height:550px;overflow-x: hidden;overflow-y: auto"
            @scroll="checkScroll"
        >
          <template v-if="conversation && conversation.length>0">
            <ChatBubble v-for="(item,idx) in conversation" :idx="idx" :item="item"
                        @deleteMessage="deleteMessage(item)" @setReplayMessage="sendMessage(item,$event)"
            ></ChatBubble>
          </template>
          <span v-else>Nothing Found</span>
        </vue-perfect-scrollbar>
        <ChatEntries ref="chatEntries" @sendNewMessage="sendMessage(null,$event)"></ChatEntries>
      </div>
    </b-card>
  </b-overlay>

</template>

<script>
import { hubInstance } from '@/libs/order-chat'
import { BCard, BOverlay, } from 'bootstrap-vue'
import vSelect from 'vue-select'
import EmojiPicker from '@/views/components/chat/EmojiPicker.vue'
import ChatHeader from '@/views/components/newChat/ChatHeader.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ChatEntries from '@/views/components/newChat/ChatEntries.vue'
import ChatBubble from '@/views/components/newChat/ChatBubble.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { GetConversation } from '@/libs/Api/Chat'

export default {
  name: 'OrderChat',
  title: 'Orders List',
  components: {
    ChatBubble,
    ChatEntries,
    ChatHeader,
    VuePerfectScrollbar,
    EmojiPicker,
    BCard,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      showOverlay: false,
      showEmojis: false,
      content: '',
      totalCount: null,
      hub: null,
      conversation: [],
      currentPage: 1,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      }
    }
  },
  destroyed() {
    this.hub.off('NewMessage')
    // this.hub.closeInstance()
  },
  async mounted() {
    this.hub = await hubInstance.createNewHubInstance()
    if (hubInstance.getInstanceState() !== 'Connected') {
      await hubInstance.connectToServer(() => {
        this.hubListeners()
      })
    } else {
      this.hubListeners()

    }

    await Promise.all([
      this.getConversation(true)
    ])
  },
  methods: {
    hubListeners() {
      this.hub.on('NewMessage', (res) => {
        this.hub.invoke('CheckDeliveredAndSeenAllMessages', this.$route.params.orderCode)
        if (typeof res !== 'string') {
          this.conversation.push(res)
          this.scrollToBottom()
        }
      })
      this.hub.on('CheckDeliveredSuccess', (res) => {
        this.conversation.forEach((item) => {
          item.isDeliver = true
          item.isSeen = true
        })
      })
    },
    checkScroll(event) {
      if (event.target.scrollTop === 0) {
        this.currentPage++
        if (this.currentPage <= this.totalCount) {
          this.getConversation(false)
        }
      }
    },

    sendMessage(item, content) {
      if (content) {
        try {
          this.hub.invoke('SendMessageToUser', content, this.$route.params.orderCode, item ? item.chatId.toString() : '')
          this.$refs.chatEntries.clearMessage()
        }
        catch (e) {
          console.log(e)
        }
        finally {
        }
      }
    },

    deleteMessage(item) {
      if (item.chatId) {
        try {
          this.hub.invoke('DeleteUserMessage', item.chatId.toString())
          let idx = this.conversation.findIndex(e => e.chatId === item.chatId)
          if (idx > -1) {
            this.conversation.splice(idx, 1)
            console.log(idx, this.conversation, item)
          }
        }
        catch (e) {
          console.log(e)
        }
        finally {

        }
      }
    },
    addEmoji(param) {
      this.content += param.emoji
    },
    async getConversation(scrollBottom) {
      let _this = this
      _this.showOverlay = true
      let getConversation = new GetConversation(_this)
      let data = {
        OrderCode: _this.$route.params.orderCode,
        pageNumber: scrollBottom ? 1 : _this.currentPage,
        count: 10
      }
      getConversation.setParams(data)
      await getConversation.fetch(function (content) {
        if (scrollBottom) {
          content.data.data.forEach((item) => {
            _this.conversation.push(item)
          })
        } else {
          content.data.data.reverse()
          content.data.data.forEach((item) => {
            _this.conversation.unshift(item)
          })
        }
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
        _this.$router.back()
      })
      if (scrollBottom) {
        _this.scrollToBottom()
      }
    },
    scrollToBottom() {
      if (this.conversation && this.conversation.length > 0) {
        this.$nextTick(() => {
          if (this.$refs.refChatLogPS) {
            this.$refs.refChatLogPS.$el.scrollTop = this.$refs.refChatLogPS.$el.scrollHeight
          }
        })
      }
    }
  },
}
</script>

<style>

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2a2a2a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-bg {

  background: #f2f0f7 url("../../../assets/images/svg/image.svg") repeat;
  background-size: 235px;
}
</style>
