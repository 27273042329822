<template>


  <div :id="`message-${item.chatId}`" :class="{'flex-lg-row-reverse':item.isMe}"
       class="d-flex flex-lg-row flex-column align-items-start my-1  "
  >
    <b-modal
        title="Message"
        :id="`modal-action-${idx}`"
        centered
        ok-only
        ok-title="Close"

    >
      <b-row>
        <b-col cols="12">
          <button v-if="item.isMe" class="btn btn-danger mb-25 w-100" @click="deleteMessage">
            Delete
          </button>
          <button class="btn btn-primary w-100" @click="replyMessage">
            Reply
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        title="Reply Message"
        :id="`modal-replay-${idx}`"
        centered
        ok-only
        ok-title="Send"
        @ok="setReplayMessage"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="message"
          >
            <b-form-textarea
                v-model.trim="content"
                rows="2"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <div class="" style="width: 40px;height: 40px;">
      <img class="rounded-circle shadow bg-white" src="../../../assets/images/logo/logo.png"
           style="width: 30px;height: 30px;object-fit: cover;"
      />
    </div>

    <div :class="{'align-items-end ':item.isMe}" class="d-flex  cursor-pointer flex-column  mx-lg-1 mx-25"
         @click="openActionModal"
    >
      <span>{{ item.username }}</span>
      <div :class="{'bg-white  text-black align-items-end':item.isMe,'bg-primary text-white':!item.isMe}"
           class="   my-1 rounded d-flex flex-column bubble-width"
      >
        <div v-if="item.chatParent  " class="border-bottom  p-25 w-100 text-right line-clamp-1 text-secondary"
             @click.stop="scrollToParent"
        >
          <small>{{ item.chatParent.message }}</small>
        </div>
        <p class="p-1">{{ item.message }}</p>
        <div class="w-100 p-1 d-flex align-items-center justify-content-between">
          <small class="">{{ new Date(item.createdAt).toLocaleTimeString('en-US') }}</small>
          <div v-if="item.isMe" class="d-flex align-items-center">
            <feather-icon v-if="item.isDeliver" class="position-relative " icon="CheckIcon" style="left: 10px">
            </feather-icon>
            <feather-icon v-if="item.isDeliver && item.isSeen" icon="CheckIcon">
            </feather-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatEntries from '@/views/components/newChat/ChatEntries.vue'
import ChatHeader from '@/views/components/newChat/ChatHeader.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmojiPicker from '@/views/components/chat/EmojiPicker.vue'
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ChatBubble',
  props: {
    item: {
      type: Object
    },
    idx: {
      type: Number
    }

  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    scrollToParent() {
      let item = document.getElementById(`message-${this.item.chatParent.chatId}`)
      let container = document.getElementById('chat-container')
      console.log(item)
        console.log(container)
      item.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
        container: container
      })
    },
    openActionModal() {
      this.$bvModal.show(`modal-action-${this.idx}`)
    },
    deleteMessage() {

      this.$emit('deleteMessage')
      this.$bvModal.hide(`modal-action-${this.idx}`)

    },
    setReplayMessage() {
      if (this.content) {
        this.$bvModal.hide(`modal-replay-${this.idx}`)
        this.$bvModal.hide(`modal-action-${this.idx}`)
        this.$emit('setReplayMessage', this.content)
        this.content = ''
      }
    },
    replyMessage() {
      this.$bvModal.show(`modal-replay-${this.idx}`)

    },
  },

  components: {
    ChatEntries,
    ChatHeader,
    VuePerfectScrollbar,
    EmojiPicker,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BAlert,
    BMedia,
    BFormTextarea,
    BAvatar,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style>
@media only screen and(min-width: 1040px) {
  .bubble-width {
    max-width: 100% !important
  }
}

.bubble-width {
  width: 30rem
}


.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
