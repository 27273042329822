import WebServiceRequest from '../Api/WebServiceRequest'

class GetConversation extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag('Chat/GetPreviousOrderChatByCode')

  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  GetConversation,

}
