<template>
  <div class="h-25 border p-1 rounded-top d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-lg-between ">
    <div class="d-flex align-items-center mb-25 ">
      <img class="bg-white shadow rounded-circle" src="../../../assets/images/logo/logo.png"
           style="width: 40px;height: 40px; object-fit: cover"
      />
      <span class="ml-1 h5 mb-0 ">{{ $route.params.orderCode }}</span>
    </div>
    <button @click="$router.back()" class="btn btn-primary">Back</button>
  </div>
</template>

<script>
export default {
  name: 'ChatHeader'
}
</script>

<style scoped>

</style>
